/*
     __  __            _        _         _
    |  \/  | __ _ _ __| | _____| |_ _ __ | | __ _  ___ ___
    | |\/| |/ _` | '__| |/ / _ \ __| '_ \| |/ _` |/ __/ _ \
    | |  | | (_| | |  |   <  __/ |_| |_) | | (_| | (_|  __/
    |_|  |_|\__,_|_|  |_|\_\___|\__| .__/|_|\__,_|\___\___|
                                   |_|

 */

import { initTranslation } from "../modules/i18n.js";
import initPage from "../modules/page";
import initDepartments from "../modules/departments.js";
import { initCarousels } from "../modules/product-collection";
import "../../scss/error.scss";

async function initErrorPage() {
  await initTranslation();
  initDepartments();
  initPage();
  initCarousels();
}

initErrorPage();
